.small-logo-con {
  position: absolute;
  left: 0;
}

.small-logo-deals-page {
  position: absolute;
}

.small-logo-con img,
.small-logo-deals-page img {
  height: 100px;
}

.height100 {
  height: 100px;
}

.description {
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 250px;
}

.optional-tabs-con {
  margin-right: 260px;
  display: inline-block;
  vertical-align: -52px;
  text-align: right;
}

.top-nav-btns {
  text-align: center;
  width: 13%;
  padding: 12px 0;
  border: 1px solid #dadada;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: 189px;
  margin-left: 5px;
  cursor: pointer;
  position: relative;
}

.top-nav-btns-focused {
  border: #9fbacd 1px solid;
  background-color: #ffffff;
}

.tab-selected-connnector {
  left: -1px;
  border-right: #9fbacd 1px solid;
  border-left: #9fbacd 1px solid;
  position: absolute;
  height: 24px;
  /* background-color: #f1f1f1; */
  background-color: #ffffff;
  width: 100%;
}

.icon-alignment {
  vertical-align: -7px;
  margin-left: 3px;
}

.inner-con {
  border: #9fbacd 1px solid;
  padding: 0 20px 20px 20px;
  background-color: #ffffff;
}

.pdf-viewer-con {
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-radius: 8px;
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.pdf-page-controls {
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-radius: 4px;
}

.pdf-page-controls button {
  width: 44px;
  height: 44px;
  background: white;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;
}

.pdf-page-controls .btn-enabled:enabled:hover,
.pdf-page-controls .btn-enabled:enabled:focus {
  background-color: lightgray;
  cursor: pointer;
}

.register-top {
  color: white;
  height: 70px;
  line-height: 70px;
  font-weight: 900;
  width: 100%;
  margin-top: 5px;
  text-align: center;
  background-color: #12344c;
}

.accept-terms-txt {
  display: inline-block;
  width: 90%;
  margin-top: 20px;
  vertical-align: -12px;
}

.text-field-with-icon {
  position: absolute;
  right: -35px;
  top: 4.5px;
  border: 1px solid #ccc;
  padding: 4.7px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.text-field-with-icon i {
  color: #555;
}

.email-conf-client {
  max-width: 700px;
  word-break: break-all;
  text-align: left;
}

.pinui-binui-container {
  width: 500px;
  position: relative;
}

.tikrat-shovi-icon {
  position: absolute;
  left: 0;
  top: 3px;
  z-index: 1;
}

.ironit-invalid-form-validation-msg {
  display: inline-block;
  margin-right: 10px;
  margin-top: 8px;
  color: #ff4800;
  max-width: 355px;
}
/* 
.yellow-sum-background {
  background-color: #fbae08;
  color: #152c3a;
  display: inline-block;
  min-width: 375px;
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
  margin-top: -1px;
  margin-right: -2px;
  height: 103%;
}

.final-msg {
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.sum-con {
  width: 100%;
  text-align: left;
  display: flex;
  direction: ltr;
  position: relative;
}

.sum-inner-con {
  border: #fbae08 2px solid;
  direction: rtl;
  display: flex;
  height: 40px;
  text-align: center;
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
  box-shadow: 4px 5px 3px 0 rgb(16 36 94 / 20%);
}

.yellow-sum-background h2 {
  margin-top: 7px;
}

.sum-total-number {
  width: 170px;
  text-align: center;
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  background-color: white;
}

.sum-total-number h2 {
  margin-top: 7px;
}

.sum-just-line {
  height: 3px;
  flex-basis: 100%;
  margin-top: 21px;
  background-color: lightgray;
} */

@media only screen and (max-width: 1340px) {
  .small-logo-con {
    display: none;
  }
}

@media only screen and (max-width: 1260px) {
  .sm-hide-txt {
    display: none;
  }

  .top-nav-btns {
    width: 100px;
  }

  .small-logo-con {
    display: inline-block;
  }
}

@media only screen and (max-width: 535px) {
  .accept-terms-txt {
    width: 80%;
    vertical-align: -44px;
  }
}
