.accessibility-window {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.accessibility-button {
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 120px;
  height: 100px;
  background-color: white;
  color: rgb(27, 43, 57);
  font-weight: 600;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.2);
  margin: 25px;
  padding: 20px;
}

.add-remove-buttons {
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.accessibility-button-line {
  width: 1px;
  height: 80%;
  background-color: black;
}

.add-remove-div {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
}

.zoom-level-1 {
  zoom: 1.2;
}

.zoom-level-2 {
  zoom: 1.4;
}

.zoom-level-3 {
  zoom: 1.6;
}

.font-increase-size-level-1 p,
.font-increase-size-level-1 h1,
.font-increase-size-level-1 h2,
.font-increase-size-level-1 h3,
.font-increase-size-level-1 h4,
.font-increase-size-level-1 h5,
.font-increase-size-level-1 h6,
.font-increase-size-level-1 span {
  transform: scale(1.15);
  transform-origin: center right;
  margin-left: 15px;
}

.font-increase-size-level-2 p,
.font-increase-size-level-2 h1,
.font-increase-size-level-2 h2,
.font-increase-size-level-2 h3,
.font-increase-size-level-2 h4,
.font-increase-size-level-2 h5,
.font-increase-size-level-2 h6,
.font-increase-size-level-2 span {
  transform: scale(1.3);
  transform-origin: center right;
  margin-left: 30px;
}

.line-height-increase-level-1 p {
  line-height: 1.4;
}

.line-height-increase-level-2 p {
  line-height: 1.6;
}

.line-height-increase-level-3 p {
  line-height: 1.8;
}

.letter-space-increase-level-1 p,
.letter-space-increase-level-1 h1,
.letter-space-increase-level-1 h2,
.letter-space-increase-level-1 h3,
.letter-space-increase-level-1 h4,
.letter-space-increase-level-1 h5,
.letter-space-increase-level-1 h6,
.letter-space-increase-level-1 span {
  letter-spacing: 1.5px;
  margin-left: 5px;
}

.letter-space-increase-level-2 p,
.letter-space-increase-level-2 h1,
.letter-space-increase-level-2 h2,
.letter-space-increase-level-2 h3,
.letter-space-increase-level-2 h4,
.letter-space-increase-level-2 h5,
.letter-space-increase-level-2 h6,
.letter-space-increase-level-2 span {
  letter-spacing: 3px;
  margin-left: 5px;
}

.link-highlight a {
  border: 2px solid rgb(178, 245, 101);
  background-color: rgb(151, 182, 252) !important;
}

.large-mouse-cursor * {
  cursor: url("/src/assets/images/large-cursor-icon.png") 16 16, auto;
}

.accessibility-icon-size {
 font-size: 40px !important;
}
