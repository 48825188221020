.calc-nav {
  list-style-type: none;
  text-align: right;
  padding: 0;
  width: 100%;
}

.calc-nav li {
  display: inline-block;
  width: 33%;
  position: relative;
  height: 54px;
}

.step-bg-high,
.last-step-bg,
.step-bg {
  width: 103%;
  position: absolute;
  height: 54px;
}

.step-bg-high {
  z-index: 3;
}

.step-bg {
  z-index: 2;
}

.text-and-step {
  position: absolute;
  right: 10px;
  top: 11px;
  z-index: 4;
  color: black;
}

.wizard-step-img {
  width: 33px;
  height: 33px;
  vertical-align: -11px;
  margin-left: 5px;
}

.step-num {
  position: absolute;
  right: 12.5px;
  top: 7px;
}

.finished-step-con {
  position: absolute;
  z-index: 4;
  left: 3px;
  top: 16px;
}

.phat-title {
  position: absolute;
  font-size: 20px;
  transform: translateY(-5px);
}

.import-deductions-con {
  position: absolute;
  top: 10px;
  right: 80px;
}

.import-deductions-con span {
  color: #152c3a;
  padding: 3px 6px;
  border-radius: 6px;
  background-color: #ffb109;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.import-deductions-con span:hover {
  background-color: rgb(178, 123, 6);
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.import-deductions-con span:not(:first-child) {
  margin-right: 10px;
}

.import-deductions-con svg {
  vertical-align: -2px;
  font-size: 16px;
}

.import-deductions-con .rotate45 svg {
  transform: rotate(45deg);
}

.td-size-200 {
  width: 200px;
}

.seller-part-container {
  display: flex;
  width: 50%;
}

.seller-part-fields {
  flex-basis: 50%;
  position: relative;
}

.seller-part-division-slash {
  position: absolute;
  top: 44%;
  right: calc(50% + 92px);
  font-size: 40px;
}

.ironit-input {
  width: 133px;
}

.ironit-currency-label {
  background-color: #ffb109;
  padding: 3px 9px;
  border-radius: 5px;
  font-size: 15px;
}

.helper-and-text-wrapper {
  margin-right: 10px;
  line-height: 1.5;
}

.helper-wrapper {
  display: inline-block;
  vertical-align: 1.4px;
  margin-right: 10px;
}