.docs-nav ul {
  list-style-type: none;
  padding-right: 0;
}

.docs-nav li {
  display: inline-block;
  width: 33%;
  border: 1px solid #dadada;
  padding: 10px 0;
}

.docs-nav li svg {
  vertical-align: -6px;
  margin-left: 5px;
}
