body {
  background: #f1f1f1 !important;
  direction: rtl !important;
  text-align: right !important;
  color: #787878;
}

.swal2-container {
  z-index: 1600 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.bold {
  font-weight: bold;
}

.container {
  width: 85%;
  margin: 0 auto;
}

.container-sm {
  width: 55%;
  margin: 0 auto;
}

.custom-progress-bar {
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99998;
}

.progress-bar-position {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.opacity04 {
  opacity: 0.4;
}

.inline-block {
  display: inline-block;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.fs-40 {
  font-size: 40px;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.width40 {
  width: 40%;
}

.width100 {
  width: 100%;
}

.active-color {
  color: #ff4800;
}

.success-color {
  color: #28a745;
}

.blue-color {
  color: #4a74cf;
}

.primary-color {
  color: #2f4280;
}

.yellow-color {
  color: #fbae08;
}

.black-color {
  color: black;
}

.white-color {
  color: white;
}

.gray-color {
  color: gray;
}

.bg-white {
  background: white;
}

.very-light-gray {
  color: #c8c8c8;
}

.field-strech {
  width: 100%;
}

.pt-10 {
  padding-top: 10px;
}

.pt-70 {
  padding-top: 70px;
}

.-vt-10 {
  vertical-align: -10px;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-40 {
  margin-bottom: 40px;
}

.block {
  display: block;
}

.bordered {
  border: 1px lightgray solid;
  border-radius: 5px;
  padding: 5px;
}

.padding10 {
  padding: 10px;
}

.padding13 {
  padding: 13px;
}

.underline {
  text-decoration: underline;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.loader-wrapper {
  position: fixed;
  background: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  opacity: 0.4;
}

.loader-style {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
}

/* .MuiTypography-root.MuiTypography-h6 {
  margin-left: 20px;
} */

/* .MuiDialog-root {
  z-index: 1000 !important;
} */

.flex-direction-row {
  flex-direction: row !important;
}

.flex {
  display: flex !important;
  justify-content: space-around;
}

.flex-space-between {
  display: flex !important;
  justify-content: space-between;
}

.flex-basic100 > * {
  flex-basis: 100%;
}

.pr-10 {
  padding-right: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-70 {
  padding-bottom: 70px;
}

.line-over-txt {
  text-decoration: line-through;
  color: lightgray;
}

.text-wrap-down {
  word-wrap: break-word;
}

/* overriding default behavior for material design rtl */
/* :not(.MuiInputLabel-shrink).MuiInputLabel-formControl {
  right: 25px;
}

.custom-autocomplete .MuiInputLabel-formControl {
  right: initial;
} */

/* .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-32.MuiToolbar-gutters {
  direction: ltr;
}

.MuiTypography-root.MuiTypography-caption {
  direction: rtl;
} */

.pointer {
  cursor: pointer;
}

.hide {
  display: none !important;
}

@media only screen and (max-width: 1000px) {
  .container-sm {
    width: 90%;
  }
}
